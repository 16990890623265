var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "helpcenter"
    }
  }, [_c('v-select', {
    staticClass: "mb-3",
    attrs: {
      "items": _vm.tenants,
      "menu-props": {
        maxHeight: '400'
      },
      "item-text": "name",
      "item-value": "id",
      "label": "Select tenants",
      "solo": "",
      "chips": "",
      "multiple": "",
      "clearable": "",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.selectedTenantIds,
      callback: function ($$v) {
        _vm.selectedTenantIds = $$v;
      },
      expression: "selectedTenantIds"
    }
  }), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.helpcenter,
      "loading": _vm.loading,
      "footer-props": {
        'items-per-page-options': [20, 50, 100]
      },
      "items-per-page": _vm.options.itemsPerPage,
      "options": _vm.options,
      "server-items-length": _vm.helpCenterTotalCount,
      "expanded": _vm.expandedRoot,
      "single-expand": "",
      "show-expand": ""
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event;
      },
      "update:expanded": function ($event) {
        _vm.expandedRoot = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.data-table-expand",
      fn: function (_ref) {
        var item = _ref.item,
          expand = _ref.expand,
          isExpanded = _ref.isExpanded;
        return [item.items && item.items.length > 0 ? _c('td', {
          staticClass: "text-start"
        }, [_c('v-btn', {
          staticClass: "v-data-table__expand-icon",
          class: {
            'v-data-table__expand-icon--active': isExpanded
          },
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return expand(!isExpanded);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-chevron-down")])], 1)], 1) : _vm._e()];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref2) {
        var headers = _ref2.headers,
          item = _ref2.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('Items', {
          attrs: {
            "items": item.items,
            "availableLocales": _vm.availableLocales,
            "visible-chip-length": _vm.visibleChipLength,
            "expanded": _vm.expandedChild
          },
          on: {
            "create": _vm.createItem,
            "edit": _vm.editItem,
            "delete": _vm.deleteItem,
            "update:expanded": function ($event) {
              _vm.expandedChild = $event;
            }
          }
        })], 1)];
      }
    }, {
      key: `item.title`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.translations ? _c('b', [_vm._v(_vm._s(item.translations[0].title))]) : _c('b', [_vm._v(_vm._s(item.title))])];
      }
    }, {
      key: `item.created`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(_vm._f("humanDatetime")(item.created)))]), _c('div', {
          staticClass: "grey--text text--darken-1"
        }, [_vm._v(_vm._s(item.created))])];
      }
    }, {
      key: `item.translations`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-chip-group', {
          staticStyle: {
            "max-width": "550px"
          },
          attrs: {
            "show-arrows": ""
          }
        }, _vm._l(item.translations, function (translation, i) {
          return _c('v-chip', {
            key: i,
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.availableLocales.find(function (locale) {
            return locale.value === translation.locale;
          }).name) + " ")]);
        }), 1)];
      }
    }, {
      key: `item.children`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.items.length))])];
      }
    }, {
      key: `item.tenants`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [item.tenants && item.tenants.length ? _c('div', {
          staticClass: "tenants"
        }, [_vm._l(item.tenants, function (tenant, index) {
          return index < _vm.visibleChipLength ? _c('v-tooltip', {
            key: tenant.id,
            attrs: {
              "bottom": "",
              "color": "black"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref8) {
                var on = _ref8.on,
                  attrs = _ref8.attrs;
                return [_c('v-avatar', _vm._g(_vm._b({
                  staticClass: "mr-1",
                  attrs: {
                    "width": "20",
                    "height": "20",
                    "tile": "",
                    "min-width": "30"
                  }
                }, 'v-avatar', attrs, false), on), [_c('img', {
                  staticClass: "tenant-image",
                  attrs: {
                    "src": `https://assets.trainplanet.com/wlabel/${_vm.fixUrl(tenant.name)}/logos/favicon-32x32.png`,
                    "alt": "Tenant Image"
                  }
                })])];
              }
            }], null, true)
          }, [_c('span', [_vm._v(_vm._s(tenant.name))])]) : _vm._e();
        }), item.tenants.length > _vm.visibleChipLength ? _c('span', {
          staticClass: "grey--text text-caption more-text"
        }, [_vm._v(" (+" + _vm._s(item.tenants.length - _vm.visibleChipLength) + " more) ")]) : _vm._e()], 2) : _vm._e()];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "elevation": "1",
            "color": "accent",
            "dark": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.createItem(item.id);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-playlist-plus")]), _vm._v(" Add item ")], 1)], 1), _c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "elevation": "1",
            "color": "third",
            "dark": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editItem(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Edit ")], 1)], 1), _c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "color": "primary",
            "icon": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1)];
      }
    }], null, true)
  }), _c('Modify', {
    attrs: {
      "item": _vm.activeItem,
      "tenants": _vm.tenants,
      "availableLocales": _vm.availableLocales
    },
    on: {
      "item:created": _vm.itemCreated,
      "item:updated": _vm.itemUpdated,
      "item:cleared": _vm.itemCleared
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent",
      "fab": "",
      "large": "",
      "fixed": "",
      "dark": "",
      "bottom": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.createItem();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="helpcenter">
    <v-select
      v-model="selectedTenantIds"
      :items="tenants"
      :menu-props="{ maxHeight: '400' }"
      class="mb-3"
      item-text="name"
      item-value="id"
      label="Select tenants"
      solo
      chips
      multiple
      clearable
      single-line
      hide-details></v-select>

    <v-data-table
      :headers="headers"
      :items="helpcenter"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [20, 50, 100],
      }"
      :items-per-page="options.itemsPerPage"
      :options.sync="options"
      :server-items-length="helpCenterTotalCount"
      :expanded.sync="expandedRoot"
      class="elevation-1"
      single-expand
      show-expand>
      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <td v-if="item.items && item.items.length > 0" class="text-start">
          <v-btn
            @click="expand(!isExpanded)"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            class="v-data-table__expand-icon"
            icon>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </td>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <Items
            @create="createItem"
            @edit="editItem"
            @delete="deleteItem"
            :items="item.items"
            :availableLocales="availableLocales"
            :visible-chip-length="visibleChipLength"
            :expanded.sync="expandedChild" />
        </td>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <b v-if="item.translations">{{ item.translations[0].title }}</b>
        <b v-else>{{ item.title }}</b>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <div>{{ item.created | humanDatetime }}</div>
        <div class="grey--text text--darken-1">{{ item.created }}</div>
      </template>

      <template v-slot:[`item.translations`]="{ item }">
        <v-chip-group style="max-width: 550px" show-arrows>
          <v-chip v-for="(translation, i) in item.translations" :key="i" small>
            {{ availableLocales.find((locale) => locale.value === translation.locale).name }}
          </v-chip>
        </v-chip-group>
      </template>

      <template v-slot:[`item.children`]="{ item }">
        <span>{{ item.items.length }}</span>
      </template>

      <template v-slot:[`item.tenants`]="{ item }">
        <div v-if="item.tenants && item.tenants.length" class="tenants">
          <v-tooltip
            v-for="(tenant, index) in item.tenants"
            :key="tenant.id"
            bottom
            color="black"
            v-if="index < visibleChipLength">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar width="20" height="20" tile min-width="30" class="mr-1" v-bind="attrs" v-on="on">
                <img
                  :src="`https://assets.trainplanet.com/wlabel/${fixUrl(tenant.name)}/logos/favicon-32x32.png`"
                  alt="Tenant Image"
                  class="tenant-image" />
              </v-avatar>
            </template>
            <span>{{ tenant.name }}</span>
          </v-tooltip>
          <span v-if="item.tenants.length > visibleChipLength" class="grey--text text-caption more-text">
            (+{{ item.tenants.length - visibleChipLength }} more)
          </span>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row justify="end">
          <v-col md="auto">
            <v-btn @click="createItem(item.id)" elevation="1" color="accent" dark small>
              <v-icon left>mdi-playlist-plus</v-icon>
              Add item
            </v-btn>
          </v-col>

          <v-col md="auto">
            <v-btn @click="editItem(item)" elevation="1" color="third" dark small>
              <v-icon left>mdi-pencil</v-icon>
              Edit
            </v-btn>
          </v-col>

          <v-col md="auto">
            <v-btn @click="deleteItem(item)" color="primary" icon small>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <Modify
      @item:created="itemCreated"
      @item:updated="itemUpdated"
      @item:cleared="itemCleared"
      :item="activeItem"
      :tenants="tenants"
      :availableLocales="availableLocales" />

    <v-btn @click="createItem()" color="accent" fab large fixed dark bottom right>
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";
import { makeCopy } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";
import MultiDropdown from "@/components/base/MultiDropdown";
import Modify from "./components/Modify.vue";
import { mapGetters } from "vuex";
import { fixUrl, debounce } from "@/util/helpers";
import Items from "./components/Items.vue";

export default {
  components: {
    Modify,
    Items,
    MultiDropdown,
  },

  data: () => ({
    loading: false,
    expandedRoot: [],
    expandedChild: [],
    options: {},
    helpCenterTotalCount: 0,
    selectedTenantIds: [],
    helpcenter: [],
    activeItem: {},
    availableLocales: [],
    screenWidth: window.innerWidth,
    emptyTranslation: {
      locale: "en-SE",
      title: null,
      description: null,
    },
    emptyItem: {
      parentId: null,
      position: 0,
      tags: [],
      translations: [],
    },
    headers: [
      {
        text: "Position",
        value: "position",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Published",
        value: "created",
      },
      {
        text: "Translations",
        value: "translations",
      },
      {
        text: "Items",
        value: "children",
      },
      {
        text: "Tenants",
        value: "tenants",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
  }),

  provide() {
    return {
      emptyTranslation: this.emptyTranslation,
      availableLocales: this.availableLocales,
    };
  },

  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
  },

  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
    visibleChipLength() {
      switch (true) {
        case this.screenWidth >= 1800:
          return 6;
        case this.screenWidth >= 1620:
          return 3;
        default:
          return 1;
      }
    },
  },

  mounted() {
    this.getSystemOptions();
    this.listHelpCenter();
    window.addEventListener("resize", this.updateScreenWidth);
  },

  watch: {
    selectedTenantIds: {
      deep: true,
      handler: debounce(async function () {
        await this.listHelpCenter();
      }, 500),
    },
    options: {
      deep: true,
      handler: debounce(function () {
        this.listHelpCenter();
      }, 500),
    },
    tenants: {
      handler(val) {
        if (val) this.selectedTenantIds = val.map((item) => item.id);
      },
    },
    helpcenter: {
      handler() {
        this.expandedRoot = this.expandedRoot.filter((rootItem) => {
          for (let item of this.helpcenter) {
            if (item.id === rootItem.id && !item.items.length ) {
              return false;
            }
          }
          return true;
        });
      },
      deep: true,
    },
  },

  methods: {
    fixUrl,
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    async getSystemOptions() {
      try {
        const { locales } = await Trainplanet.getOrderSystemOptions();
        this.availableLocales = Object.entries(locales).map((item) => ({
          value: item[0],
          name: item[1],
        }));
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message | error);
      }
    },
    async listHelpCenter() {
      this.loading = true;
      const res = await Trainplanet.listHelpCenter({
        tenantIds: this.selectedTenantIds,
        limit: this.options.itemsPerPage,
        offset: this.options.itemsPerPage * (this.options.page - 1),
        q: this.searchQuery,
        page: this.options.page,
      });

      this.helpcenter = res.helpCenters;
      this.helpCenterTotalCount = res.meta.total;

      this.loading = false;
    },
    createItem(parentId = null) {
      const item = makeCopy(this.emptyItem); // Make copy
      const translation = makeCopy(this.emptyTranslation); // Make copy

      item.parentId = parentId;
      item.translations.push(translation);

      this.activeItem = item;
    },
    editItem(item, parentId = null) {
      this.activeItem = makeCopy(item);
      this.activeItem.parentId = parentId;
    },
    async deleteItem(item, parentId = null) {
      this.$confirm("Are you sure that you want to delete this FAQ?", {
        color: "primary",
        title: "Delete Faq",
      }).then(async (res) => {
        if (!res) return;

        this.loading = true;

        await Trainplanet.deleteHelpCenter(item.id);
        await this.listHelpCenter();
        this.$store.dispatch("success", "Item has deleted");
        this.expandedChild = this.expandedChild.filter((row) => row.items && row.items.length > 0);

        this.loading = false;
      });
    },
    itemCreated() {
      this.listHelpCenter();
      this.$store.dispatch("success", "Item has created");
    },
    itemUpdated(item) {
      const parent = this.helpcenter.findIndex((x) => x.id === (item.parentId || item.id));

      if (item.parentId) {
        const child = this.helpcenter[parent].items.findIndex((x) => x.id === item.id);
        this.$set(this.helpcenter[parent].items, child, item);
      } else {
        this.$set(this.helpcenter, parent, item);
      }
      this.listHelpCenter();
      this.$store.dispatch("success", "Action has done successfully");
    },
    itemCleared() {
      this.activeItem = {};
    },
  },
};
</script>

<style>
#helpcenter .child-item > td {
  padding: 10px 16px;
  font-size: 0.875rem;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.more-text {
  text-wrap: nowrap;
}
</style>

<template>
  <v-bottom-sheet v-model="editing" scrollable persistent>
    <v-card v-if="editing">
      <v-card-title>
        <span v-if="activeItem.id">
          Edit <b v-if="activeItem.title">{{ activeItem.title }}</b>
        </span>
        <span v-else>Add new item</span>

        <v-spacer></v-spacer>

        <v-btn @click="editing = false" icon small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text style="min-height: 55vh; max-height: 80vh">
        <v-form>
          <v-container class="mt-2">
            <v-text-field
              v-model.number="activeItem.position"
              dense
              label="Position"
              outlined
              required
              type="number"></v-text-field>

            <v-combobox
              v-model="activeItem.tags"
              :items="activeItem.tags"
              label="Tags"
              multiple
              small-chips
              outlined></v-combobox>

            <v-select
              v-model="selectedTenants"
              :items="tenants"
              item-text="name"
              item-value="id"
              menu-props="auto"
              label="Select tenant"
              multiple
              chips
              clearable
              hide-details
              outlined></v-select>

            <div class="mt-4">
              <v-tabs v-model="activeTranslation">
                <v-tab v-for="(translation, i) in activeItem.translations" :key="i">
                  {{ availableLocales.find((locale) => locale.value === translation.locale).name }}
                  <v-btn class="ml-2" small icon @click.stop="removeTranslation(i)">
                    <v-icon size="18">mdi-close</v-icon>
                  </v-btn>
                </v-tab>

                <v-select
                  ref="addTranslation"
                  @change="addTranslation"
                  :items="availableLocales | notIn(activeItem.translations)"
                  item-text="name"
                  item-value="value"
                  style="max-width: max-content"
                  placeholder="Add new translation"
                  flat
                  solo></v-select>
              </v-tabs>

              <v-tabs-items v-model="activeTranslation" class="mt-4">
                <v-tab-item v-for="(translation, i) in activeItem.translations" :key="i">
                  <v-text-field
                    v-model="translation.title"
                    label="Title"
                    class="mt-2"
                    outlined
                    dense
                    required></v-text-field>
                  <v-btn
                    class="elevation-2 mb-1"
                    small
                    @click="
                      convertToRawHtml();
                      isRawHtmlVisible = !isRawHtmlVisible;
                    "
                    >{{ isRawHtmlVisible ? "close" : "open" }} raw html</v-btn
                  >
                  <vue-editor ref="editor" v-model="translation.description"></vue-editor>
                  <textarea
                    v-show="isRawHtmlVisible"
                    v-model="raw_html"
                    style="width: 100%; border: 1px solid #ccc; margin-top: 10px; padding: 5px"></textarea>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-tooltip bottom :content-class="isValid ? 'empty-tooltip' : ''">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="w-100">
              <v-btn @click="saveItem()" :disabled="loading || !isValid" color="accent" block> Save </v-btn>
            </div>
          </template>
          <span>{{
            !isValid
              ? "Please ensure to select a tenant and add at least one translation, with each translation including a Title."
              : ""
          }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { makeCopy } from "@/util/helpers";
import { VueEditor } from "vue2-editor";
import Trainplanet from "@/util/trainplanet.api";

export default {
  name: "CreateOrEdit",
  props: {
    item: Object,
    tenants: Array,
    availableLocales: Array,
  },
  components: {
    VueEditor,
  },
  inject: ["emptyTranslation"],
  data: () => ({
    isRawHtmlVisible: false,
    loading: false,
    raw_html: "",
    activeTranslation: null,
    e2: "Texas",
    states: ["Alabama", "Alaska", "American Samoa", "Wisconsin", "Wyoming"],
    selectedTenants: [],
  }),
  computed: {
    isValid() {
      return (
        this.activeItem.translations.length > 0 &&
        this.activeItem.translations.every((translation) => translation?.title?.trim()) &&
        this.selectedTenants.length
      );
    },
    activeItem() {
      return this.item;
    },
    editing: {
      get() {
        return Object.keys(this.activeItem).length !== 0;
      },
      set(doing) {
        if (!doing) {
          this.$emit("item:cleared");
        }
      },
    },
  },
  watch: {
    activeTranslation(value) {
      this.convertToRawHtml();
    },
    activeItem(value) {
      if (value.tenants) {
        this.selectedTenants = value.tenants.map((tenant) => tenant.id);
      } else {
        this.selectedTenants = [];
      }
    },
    raw_html(val) {
      if (!this.activeItem.translations[this.activeTranslation]) return;
      this.activeItem.translations[this.activeTranslation].description = val;
    },
    editing(val) {
      if (val) {
        setTimeout(() => {
          this.raw_html = document.querySelector(".ql-editor").innerHTML;
        });
      }
    },
    selectedTenants() {
      this.ensureAlwaysSelected(this.selectedTenants);
    },
  },
  filters: {
    notIn(locales, translations) {
      return locales.filter(
        (locale) => !translations.some((translation) => translation.locale.toUpperCase() === locale.value.toUpperCase())
      );
    },
  },
  methods: {
    ensureAlwaysSelected(selectedItems) {
      const trainplanetId = "6ef7e19b-69b0-4805-b8a0-81bf7c3ca1d9";
      if (!selectedItems.length) {
        this.selectedTenants.push(trainplanetId);
      }
    },
    removeTranslation(index) {
      this.activeItem.translations.splice(index, 1);
    },
    isEmptyContent(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      return !div.textContent?.trim();
    },
    convertToRawHtml() {
      this.raw_html = this.activeItem.translations[this.activeTranslation]?.description;
    },
    addTranslation(locale) {
      const translation = makeCopy(this.emptyTranslation); // Make copy
      translation.locale = locale;
      this.activeItem.translations.push(translation);
      this.$refs.addTranslation.reset(); // Reset the select input
    },
    async saveItem() {
      let response;
      const { id, parentId, ...item } = this.activeItem;

      this.loading = true;

      const post_item = {
        tenantIds: this.selectedTenants,
        position: item.position,
        tags: item.tags,
        translations: item.translations,
      };

      if (id) {
        response = await Trainplanet.updateHelpCenter(id, post_item);
      } else {
        response = await Trainplanet.createHelpCenter(parentId, post_item);
        this.$emit("item:created", response);
      }

      if (id || parentId) {
        if (response.id !== parentId) response.parentId = parentId;

        this.$emit("item:updated", response);
      }

      this.editing = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-tooltip {
  display: none !important;
}

.w-100 {
  width: 100%;
}

::v-deep .v-select__selections {
  min-height: 56px !important;
}
</style>

<template>
  <div class="py-4">
    <v-data-table
      :items="items"
      :headers="headers"
      :hide-default-footer="true"
      :disable-pagination="true"
      :expanded.sync="expandedLocal"
      show-expand
      class="elevation-1 data-table">
      <template v-slot:[`item.position`]="{ item }">
        <span class="ml-2">{{ item.position }}</span>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <span class="ml-2">{{ item.translations[0].title }}</span>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <div>{{ item.created | humanDatetime }}</div>
        <div class="grey--text text--darken-1">{{ item.created }}</div>
      </template>

      <template v-slot:[`item.translations`]="{ item }">
        <v-chip-group show-arrows>
          <v-chip v-for="(translation, i) in item.translations" :key="i" small>
            {{ availableLocales.find((locale) => locale.value === translation.locale).name }}
          </v-chip>
        </v-chip-group>
      </template>

      <template v-slot:[`item.children`]="{ item }">
        <span>{{ item.items.length }}</span>
      </template>

      <template v-slot:[`item.tenants`]="{ item }">
        <div v-if="item.tenants && item.tenants.length" class="tenants">
          <v-tooltip v-for="(tenant, index) in item.tenants" :key="tenant.id" bottom color="black" v-if="index < visibleChipLength">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar width="20" height="20" tile min-width="30" class="mr-1" v-bind="attrs" v-on="on">
                <img
                  :src="`https://assets.trainplanet.com/wlabel/${fixUrl(tenant.name)}/logos/favicon-32x32.png`"
                  alt="Tenant Image"
                  class="tenant-image" />
              </v-avatar>
            </template>
            <span>{{ tenant.name }}</span>
          </v-tooltip>
          <span v-if="item.tenants.length > visibleChipLength" class="grey--text text-caption more-text">
            (+{{ item.tenants.length - visibleChipLength }} more)
          </span>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row justify="end">
          <v-col md="auto">
            <v-btn @click="createItem(item.id)" elevation="1" color="accent" dark small>
              <v-icon left>mdi-playlist-plus</v-icon>
              Add item
            </v-btn>
          </v-col>
          <v-col sm="auto">
            <v-btn @click="editItem(item, item.id)" elevation="1" color="third" dark small>
              <v-icon left>mdi-pencil</v-icon>
              Edit
            </v-btn>
          </v-col>
          <v-col sm="auto">
            <v-btn @click="deleteItem(item)" color="primary" icon small>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <Items
            @create="createItem"
            @edit="editItem"
            @delete="deleteItem"
            :items="item.items"
            :availableLocales="availableLocales"
            :depth="depth + 1" />
        </td>
      </template>

      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <td v-if="item.items && item.items.length > 0" class="text-start">
          <v-btn
            icon
            @click="expand(!isExpanded)"
            class="v-data-table__expand-icon"
            :class="{ 'v-data-table__expand-icon--active': isExpanded }">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { fixUrl } from "@/util/helpers";

export default {
  name: "Items",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    availableLocales: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Array,
      default: () => [],
    },
    depth: {
      type: Number,
      default: 1,
    },
    visibleChipLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      expandedLocal: this.expanded,
      headers: [
        {
          text: "Position",
          value: "position",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Published",
          value: "created",
        },
        {
          text: "Translations",
          value: "translations",
        },
        {
          text: "Items",
          value: "children",
        },
        {
          text: "Tenants",
          value: "tenants",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  watch: {
    expanded: {
      handler(newVal) {
        this.expandedLocal = newVal;
      },
      immediate: true,
    },
    expandedLocal: {
      handler(newVal) {
        this.$emit("update:expanded", newVal);
      },
      immediate: true,
    },
    items: {
      handler() {
        this.expandedLocal = this.expandedLocal.map((item) => {
          const newItem = this.items.find((i) => i.id === item.id);
          return newItem ? { ...newItem } : item;
        });
      },
      deep: true,
    },
  },
  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
  },
  methods: {
    fixUrl,
    createItem(id) {
      this.$emit("create", id);
    },
    editItem(item, id) {
      this.$emit("edit", item, id);
    },
    deleteItem(item) {
      this.$emit("delete", item);
    },
  },
};
</script>

<style scoped>
::v-deep .data-table .v-data-table__wrapper table tbody tr td:nth-child(3) {
  max-width: 300px;
  padding: 1rem;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', {
    attrs: {
      "scrollable": "",
      "persistent": ""
    },
    model: {
      value: _vm.editing,
      callback: function ($$v) {
        _vm.editing = $$v;
      },
      expression: "editing"
    }
  }, [_vm.editing ? _c('v-card', [_c('v-card-title', [_vm.activeItem.id ? _c('span', [_vm._v(" Edit "), _vm.activeItem.title ? _c('b', [_vm._v(_vm._s(_vm.activeItem.title))]) : _vm._e()]) : _c('span', [_vm._v("Add new item")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.editing = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticStyle: {
      "min-height": "55vh",
      "max-height": "80vh"
    }
  }, [_c('v-form', [_c('v-container', {
    staticClass: "mt-2"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "label": "Position",
      "outlined": "",
      "required": "",
      "type": "number"
    },
    model: {
      value: _vm.activeItem.position,
      callback: function ($$v) {
        _vm.$set(_vm.activeItem, "position", _vm._n($$v));
      },
      expression: "activeItem.position"
    }
  }), _c('v-combobox', {
    attrs: {
      "items": _vm.activeItem.tags,
      "label": "Tags",
      "multiple": "",
      "small-chips": "",
      "outlined": ""
    },
    model: {
      value: _vm.activeItem.tags,
      callback: function ($$v) {
        _vm.$set(_vm.activeItem, "tags", $$v);
      },
      expression: "activeItem.tags"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.tenants,
      "item-text": "name",
      "item-value": "id",
      "menu-props": "auto",
      "label": "Select tenant",
      "multiple": "",
      "chips": "",
      "clearable": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.selectedTenants,
      callback: function ($$v) {
        _vm.selectedTenants = $$v;
      },
      expression: "selectedTenants"
    }
  }), _c('div', {
    staticClass: "mt-4"
  }, [_c('v-tabs', {
    model: {
      value: _vm.activeTranslation,
      callback: function ($$v) {
        _vm.activeTranslation = $$v;
      },
      expression: "activeTranslation"
    }
  }, [_vm._l(_vm.activeItem.translations, function (translation, i) {
    return _c('v-tab', {
      key: i
    }, [_vm._v(" " + _vm._s(_vm.availableLocales.find(function (locale) {
      return locale.value === translation.locale;
    }).name) + " "), _c('v-btn', {
      staticClass: "ml-2",
      attrs: {
        "small": "",
        "icon": ""
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.removeTranslation(i);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "18"
      }
    }, [_vm._v("mdi-close")])], 1)], 1);
  }), _c('v-select', {
    ref: "addTranslation",
    staticStyle: {
      "max-width": "max-content"
    },
    attrs: {
      "items": _vm._f("notIn")(_vm.availableLocales, _vm.activeItem.translations),
      "item-text": "name",
      "item-value": "value",
      "placeholder": "Add new translation",
      "flat": "",
      "solo": ""
    },
    on: {
      "change": _vm.addTranslation
    }
  })], 2), _c('v-tabs-items', {
    staticClass: "mt-4",
    model: {
      value: _vm.activeTranslation,
      callback: function ($$v) {
        _vm.activeTranslation = $$v;
      },
      expression: "activeTranslation"
    }
  }, _vm._l(_vm.activeItem.translations, function (translation, i) {
    return _c('v-tab-item', {
      key: i
    }, [_c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "label": "Title",
        "outlined": "",
        "dense": "",
        "required": ""
      },
      model: {
        value: translation.title,
        callback: function ($$v) {
          _vm.$set(translation, "title", $$v);
        },
        expression: "translation.title"
      }
    }), _c('v-btn', {
      staticClass: "elevation-2 mb-1",
      attrs: {
        "small": ""
      },
      on: {
        "click": function ($event) {
          _vm.convertToRawHtml();
          _vm.isRawHtmlVisible = !_vm.isRawHtmlVisible;
        }
      }
    }, [_vm._v(_vm._s(_vm.isRawHtmlVisible ? "close" : "open") + " raw html")]), _c('vue-editor', {
      ref: "editor",
      refInFor: true,
      model: {
        value: translation.description,
        callback: function ($$v) {
          _vm.$set(translation, "description", $$v);
        },
        expression: "translation.description"
      }
    }), _c('textarea', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isRawHtmlVisible,
        expression: "isRawHtmlVisible"
      }, {
        name: "model",
        rawName: "v-model",
        value: _vm.raw_html,
        expression: "raw_html"
      }],
      staticStyle: {
        "width": "100%",
        "border": "1px solid #ccc",
        "margin-top": "10px",
        "padding": "5px"
      },
      domProps: {
        "value": _vm.raw_html
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.raw_html = $event.target.value;
        }
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": _vm.isValid ? 'empty-tooltip' : ''
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "w-100"
        }, 'div', attrs, false), on), [_c('v-btn', {
          attrs: {
            "disabled": _vm.loading || !_vm.isValid,
            "color": "accent",
            "block": ""
          },
          on: {
            "click": function ($event) {
              return _vm.saveItem();
            }
          }
        }, [_vm._v(" Save ")])], 1)];
      }
    }], null, false, 4045631674)
  }, [_c('span', [_vm._v(_vm._s(!_vm.isValid ? "Please ensure to select a tenant and add at least one translation, with each translation including a Title." : ""))])])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }